import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { BreadCrumbNavigation } from './components/global-components/header/BreadCrumbNavigation/BreadCrumbNavigation';
import React, { useEffect } from 'react';
import SignInRequest from './components/auth-components/SignInRequest';
import { loginRequest } from './authConfig';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import CustomSpinner from './components/ui-components/CustomSpinner/CustomSpinner';
import { InteractionStatus } from "@azure/msal-browser";

const App = () => {

  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  useEffect(() => {
    return () => {
      if (!isAuthenticated) {
        instance.loginRedirect(loginRequest).then(response => response)
      }
    }
  }, [])


  return (
    <BrowserRouter>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        loadingComponent={CustomSpinner}
      >
        {
          isAuthenticated &&
          <div className='App'>
            {(inProgress !== InteractionStatus.Login) && <BreadCrumbNavigation />}
          </div>
        }
      </MsalAuthenticationTemplate>
    </BrowserRouter>
  );
}

export default App;

