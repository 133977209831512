import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';

export const Error = () => {
    const navigate = useNavigate();
    const [showErrorMessage, setshowErrorMessage] = useState(true)
    const handleClose = () => {
        setshowErrorMessage(false)
        navigate('/')
        navigate(0);
    };
    const [redirectTimer, setRedirectTimer] = useState(30)

    useEffect(() => {
        setTimeout(updateTimer, 1000);
    }, [redirectTimer])

    const updateTimer = () => {
        let timeOut = redirectTimer - 1;
        setRedirectTimer(timeOut);
        if (timeOut <= 0) {
            handleClose();
        }
    }
    return (
        <Modal show={showErrorMessage} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>An unexpected error has occured.</Modal.Title>
            </Modal.Header>
            <Modal.Body>An error has occured. You will be redirected to the home page in <span className='text-success'>{redirectTimer}</span> seconds to re-start the sampling process. Please refer to the Upload Instructions on the first page to ensure the Excel file is formatted correctly. If the error persists, please speak to a member of the support team.</Modal.Body>
            <Modal.Footer>
                <button
                    className='btn btn-success text-uppercase'
                    id='btn-sampling-next'
                    onClick={handleClose}>Redirect Now</button>
            </Modal.Footer>
        </Modal>
    )
}
