import { Row, Col } from 'react-bootstrap'
import { PageHeading } from '../components/global-components/header/PageHeading';
import { useNavigate } from 'react-router-dom';
import '../main.css';

export const NotFound = () => {
    let navigate = useNavigate();

    return (
        <div className='w-1200-wrapper'>
            <div className='custom-container-constraint-left'>
                <Row>
                    <Col md={{ span: 12 }}>
                        <PageHeading label='Oops! Something has gone wrong here...' />
                        <hr />
                        The resource you are looking for has been removed, has its name changed, or is temporarily unavailable unavailable.
                    </Col>
                </Row>
                <Row className='mt-5'>
                    <Col md={{ span: 6 }}>
                        <button className='btn btn-outline-primary text-uppercase' onClick={() => navigate(-1)}>Previous Page</button>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
