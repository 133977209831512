import React, { useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap';
import { PageHeading } from './../components/global-components/header/PageHeading';
import { useNavigate } from 'react-router-dom';
import CustomSpinner from '../components/ui-components/CustomSpinner/CustomSpinner';
import {
    AddSampleStart,
    AddSampleEnd,
    AddSampleDataColumn,
    AddAbsoluteValueOfNegativeItems,
    AddBalance,
    AddItemsAboveMaterialityThreshold,
    AddNegativeItems,
    AddTotalItems,
    AddValueOfItemsAboveMateriality
} from '../state-management/workbook/workbookSlice';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import './DefineSheetInformation.css';
import '../main.css';
import { Error } from '../components/global-components/error/Error';

export const DefineSheetInformation = () => {
    const workbook = useSelector((state) => state.sheets);
    const [sampleStart, setSampleStart] = useState(1)
    const [isSampleStartValid, setIsSampleStartValid] = useState(true)
    const [SampleStartValidationMessage, setSampleStartValidationMessage] = useState('')
    const [sampleEnd, setSampleEnd] = useState(0)
    const [isSampleEndValid, setIsSampleEndValid] = useState(false)
    const [SampleEndValidationMessage, setSampleEndValidationMessage] = useState('')
    const [sampleDataColumn, setsampleDataColumn] = useState(0)
    const [isDataColumnValid, setIsDataColumnValid] = useState(false)
    const [dataColumnValidationMessage, setDataColumnValidationMessage] = useState('')

    // Setup the page navigation.
    const navigate = useNavigate();
    // Setup outwards comms with redux using custom hook.
    const dispatch = useDispatch();
    const [isLoading, setisLoading] = useState(false)
    const [error, setError] = useState(false)

    const OnConfirmInformation = async () => {
        setisLoading(true);
        dispatch(AddSampleStart(sampleStart));
        dispatch(AddSampleEnd(sampleEnd));
        dispatch(AddSampleDataColumn(sampleDataColumn));
        let response = await postInformation();
        dispatch(AddAbsoluteValueOfNegativeItems(response.absoluteValueOfNegativeItems));
        dispatch(AddBalance(response.balance));
        dispatch(AddItemsAboveMaterialityThreshold(response.itemsAboveMaterialityThreshold));
        dispatch(AddNegativeItems(response.negativeItems));
        dispatch(AddTotalItems(response.totalItems));
        dispatch(AddValueOfItemsAboveMateriality(response.valueOfItemsAboveMateriality));
        setisLoading(false);
        navigate('/sampling-calculator-1');
    }

    const postInformation = async () => {
        let bearer = `Bearer ${workbook.tokenAuth}`;

        setisLoading(true);

        let config = {
            headers: {
                'Authorization': `${bearer}`,
                'Content-Type': 'application/json',
            }
        }
        let body = {
            sheetName: workbook.selectedSheet,
            tableStart: sampleStart,
            tableEnd: sampleEnd,
            columnLetter: sampleDataColumn ? sampleDataColumn.toUpperCase() : '',
            materialityThreshold: workbook.workbookMateriality,
            containerName: workbook.workbookContainer,
            fileName: workbook.workbookFileName,
        }

        const url = process.env.REACT_APP_FIND_VALUES;
        try {
            return await axios.post(url, body, config)
                .then((response) => {
                    return response.data
                });
        } catch (error) {
            console.log(error)
            setError(error)
        }
    }

    const handleSampleStartChange = (e) => {
        let value = parseInt(e.target.value);

        if (isNaN(value)) {
            setIsSampleStartValid(false);
            setSampleStartValidationMessage('Please enter a row number')
            return;
        }

        if (value === 0) {
            setIsSampleEndValid(false);
            setSampleStartValidationMessage('Excel columns begin from row 1.')
            return;
        }

        if (value > 1048576) {
            setIsSampleEndValid(false);
            setSampleStartValidationMessage('Excel columns cannot exceed 1,048,576 rows.')
            return;
        }

        if (value > 0 && value <= 1048576) {
            setIsSampleStartValid(true);
        }

        setSampleStart(value)
    }

    const handleSampleEndChange = (e) => {
        let value = parseInt(e.target.value);

        if (isNaN(value)) {
            setIsSampleEndValid(false);
            setSampleEndValidationMessage('Please enter a row number')
            return;
        }

        if (value === 0) {
            setIsSampleEndValid(false);
            setSampleEndValidationMessage('Excel columns begin from row 1.')
            return;
        }

        if (value > 1048576) {
            setIsSampleEndValid(false);
            setSampleEndValidationMessage('Excel columns cannot exceed 1,048,576 rows.')
            return;
        }

        if (value <= sampleStart) {
            setIsSampleEndValid(false);
            setSampleEndValidationMessage('Sample end must be more than sample start.')
            return;
        }

        if (value > 0 && value <= 1048576) {
            setIsSampleEndValid(true);
        }

        setSampleEnd(value)
    }

    const handleDataColumnChange = (e) => {
        let value = e.target.value
        setsampleDataColumn(value)
        if (value.search(/[^a-zA-Z]+/) === -1 && value.length === 1) {
            setIsDataColumnValid(true)
        }
        else {
            setIsDataColumnValid(false)
            setDataColumnValidationMessage('Columns are limited from A to Z single character only.')
        }
    }

    return (
        <div className='w-1200-wrapper'>
            {error && <Error />}
            <div className='custom-container-constraint-left'>
                <Row>
                    <Col md={{ span: 12 }}>
                        <PageHeading label='Define sheet information' />
                        <CustomSpinner isLoading={isLoading} />
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 11 }}>
                        <Row>
                            <Form.Group className='mb-3' controlId='table-start'>
                                <Form.Label htmlFor='starting-row'>Starting Row</Form.Label>
                                <Form.Control id='starting-row' type='number' placeholder='1' min='1' max='1048576' style={{ maxWidth: '12%' }} isInvalid={!isSampleStartValid} onChange={(e) => handleSampleStartChange(e)} />
                                <Form.Control.Feedback type="invalid">{SampleStartValidationMessage}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group className='mb-3' controlId='table-end'>
                                <Form.Label htmlFor='ending-row'>Ending Row</Form.Label>
                                <Form.Control id='ending-row' type='number' placeholder='0' min='1' max='1048576' style={{ maxWidth: '12%' }} required isInvalid={!isSampleEndValid} onChange={(e) => handleSampleEndChange(e)} />
                                <Form.Control.Feedback type="invalid">{SampleEndValidationMessage}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group className='mb-3' controlId='table-column-id'>
                                <Form.Label htmlFor='sample-column'>Sample Column</Form.Label>
                                <Form.Control id='sample-column' type='text' placeholder='E' style={{ maxWidth: '12%' }} required isInvalid={!isDataColumnValid} onChange={(e) => handleDataColumnChange(e)} />
                                <Form.Control.Feedback type="invalid">{dataColumnValidationMessage}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col md={{ span: 3 }}>
                        <a className='btn btn-outline-primary text-uppercase' href='/'>Start Over</a>
                    </Col>
                    <Col md={{ offset: 5 }} style={{ display: 'flex', justifyContent: 'end' }}>
                        <button className='btn btn-success text-uppercase' onClick={OnConfirmInformation} disabled={(!isSampleEndValid || !isDataColumnValid)}>Confirm Information</button>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
