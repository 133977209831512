import Spinner from 'react-bootstrap/Spinner';
import './CustomSpinner.css';

const CustomSpinner = ({ isLoading }) => {
    return (
        isLoading &&
        <div className='overlay'>
            <Spinner animation='border' variant='success' className='custom-spinner-theme' />
        </div>
    );
}

export default CustomSpinner;