import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import './RFactorSampleSize.css'
import { AddRFactor, AddSamplingSize } from '../../../state-management/workbook/workbookSlice';

const RFactorSampleSize = ({
    rMMImpactAssessment,
    tOCImpactAssessment,
    sAPImpactAssessment,
    oSPImpactAssessment,
    dATImpactAssessment,
    includeNegativeValueCheck,
    includeNullValueCheck,
    balance,
    totalValueOfMaterialItems,
    absoluteValueOfNegativeItems,
    workbookMateriality,
    UpdateRFactor,
    UpdateSampleSize
}) => {
    // Setup outwards comms with redux using custom hook.
    const dispatch = useDispatch();

    const [rFactor, setRFactor] = useState(0);
    const [sampleSize, setSampleSize] = useState(0);
    /**
     * 
     * rfactor value after calculation. 
     */
    const onRfactorChange = (e) => {
        UpdateRFactor(e);
        dispatch(AddRFactor(e));
    }

    /**
     * sample size value after calculation. 
     */
    const onSampleSizeChange = (e) => {
        UpdateSampleSize(e);
        dispatch(AddSamplingSize(e));
    }


    useEffect(() => {
        /**
         * Calculate the rfactor and sample size.
         */

        let rfactorResult = 0.0;

        let Assurance = tOCImpactAssessment + sAPImpactAssessment + oSPImpactAssessment + dATImpactAssessment
        if ((rMMImpactAssessment < 0.5) || (rMMImpactAssessment > tOCImpactAssessment) || (Assurance > tOCImpactAssessment))
        {
            rfactorResult = rMMImpactAssessment - (tOCImpactAssessment + sAPImpactAssessment + oSPImpactAssessment + dATImpactAssessment);
            if (rfactorResult < 0) {
                rfactorResult = 0;
            }
        }
        else {
            rfactorResult = 0.5
        }
        rfactorResult = rfactorResult.toFixed(2);

        let sampleSizeResult = 0;
        sampleSizeResult = (((balance - totalValueOfMaterialItems) + absoluteValueOfNegativeItems) * rfactorResult) / workbookMateriality;

        // Update the rfactor state on the page.
        setRFactor(rfactorResult);
        // Update the Sample Size state on the page.
        setSampleSize(sampleSizeResult);
        // Update the rfactor state in redux store.
        onRfactorChange(rfactorResult);
        // Update the Sample Size state in redux store.
        onSampleSizeChange(sampleSizeResult);

    }, [rMMImpactAssessment, tOCImpactAssessment, sAPImpactAssessment, oSPImpactAssessment, dATImpactAssessment, includeNegativeValueCheck, includeNullValueCheck, balance, totalValueOfMaterialItems, absoluteValueOfNegativeItems, workbookMateriality])


    return (
        <div className='calculation-container'>
            <span className='sampleSize'>
                <p className='text-uppercase text-center mb-0'><small><strong>sample size</strong></small></p>
                <p className='text-center h5 sample-text'>{Math.ceil(sampleSize) || 0}</p>
            </span>
            <span className='rFactor'>
                <p className='text-uppercase text-center mb-0'><small><strong>osp sampling r-factor</strong></small></p>
                <p className='text-center h5 sample-text'>{rFactor || 0}</p>
            </span>
        </div>
    );
}

export default RFactorSampleSize;