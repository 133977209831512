// hard coded configuration for rfactor and sampling size calculations.
export const config = [
    {
        title: 'IRMM Level',
        name: 'RMM_Impact',
        assessments: [
            {
                riskConsideration: 'Please select IRMM Level',
                assessment: 0.0
            },
            {
                riskConsideration: 'Low',
                assessment: 1.0
            },
            {
                riskConsideration: 'Moderate',
                assessment: 2.0
            },
            {
                riskConsideration: 'Elevated',
                assessment: 2.5
            },
            {
                riskConsideration: 'Significant',
                assessment: 3.0
            },
            {
                riskConsideration: 'SSP',
                assessment: 0.5
            }
        ]
    },
    {
        title: 'Assurance from TOCs',
        name: 'TOC_Impact',
        assessments: [
            {
                riskConsideration: 'None',
                assessment: 0.0
            },
            {
                riskConsideration: 'Yes (1.0)',
                assessment: 1.0
            },
            {
                riskConsideration: 'Yes (1.5)',
                assessment: 1.5
            },
            {
                riskConsideration: 'Yes (2.0)',
                assessment: 2.0
            }
        ]
    },
    {
        title: 'Assurance from SAPs',
        name: 'SAP_Impact',
        assessments: [
            {
                riskConsideration: 'None',
                assessment: 0.0
            },
            {
                riskConsideration: 'Yes (0.5)',
                assessment: 0.5
            },
            {
                riskConsideration: 'Yes (1.0)',
                assessment: 1.0
            },
            {
                riskConsideration: 'Yes (1.5)',
                assessment: 1.5
            },
            {
                riskConsideration: 'Yes (2.0)',
                assessment: 2.0
            }
        ]
    },
    {
        title: 'Assurance from other OSPs',
        name: 'OSP_Impact',
        assessments: [
            {
                riskConsideration: 'None',
                assessment: 0.0
            },
            {
                riskConsideration: 'Yes (0.5)',
                assessment: 0.5
            },
            {
                riskConsideration: 'Yes (1.0)',
                assessment: 1.0
            },
            {
                riskConsideration: 'Yes (1.5)',
                assessment: 1.5
            },
            {
                riskConsideration: 'Yes (2.0)',
                assessment: 2.0
            },
            {
                riskConsideration: 'Yes (2.5)',
                assessment: 2.5
            }
        ]
    },
    {
        title: 'Assurance from DATs',
        name: 'DAT_Impact',
        assessments: [
            {
                riskConsideration: 'None',
                assessment: 0.0
            },
            {
                riskConsideration: 'Yes (0.5)',
                assessment: 0.5
            },
            {
                riskConsideration: 'Yes (1.0)',
                assessment: 1.0
            },
            {
                riskConsideration: 'Yes (1.5)',
                assessment: 1.5
            },
            {
                riskConsideration: 'Yes (2.0)',
                assessment: 2.0
            },
            {
                riskConsideration: 'Yes (2.5)',
                assessment: 2.5
            }
        ]
    },
    {
        title: 'RFactor Override',
        name: 'RFactor_Override',
        assessments: [
            {
                riskConsideration: '0.1',
                assessment: 0.1
            },
            {
                riskConsideration: '0.2',
                assessment: 0.2
            },
            {
                riskConsideration: '0.3',
                assessment: 0.3
            },
            {
                riskConsideration: '0.4',
                assessment: 0.4
            },
            {
                riskConsideration: '0.5',
                assessment: 0.5
            }
        ]
    }
]