import React from 'react'
import { Row, Col } from 'react-bootstrap';

// Extract page heading as a stand alone component.
export const PageHeading = ({ label }) => {
    return (
        <Row>
            <Col md={{ span: 12 }}>
                <h2 className='page-heading text-capitalize'>{label}</h2>
            </Col>
        </Row>
    )
}
