import { React, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { PageHeading } from './../../components/global-components/header/PageHeading';
import { ToggleSwitch } from './../../components/ui-components/Toggle/ToggleSwitch';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// Redux toolkit imports to commit changes.
import {
    AddAbsoluteValueOfNegativeItems,
    AddBalance,
    AddItemsAboveMaterialityThreshold,
    AddNegativeItems,
    AddTotalItems,
    AddValueOfItemsAboveMateriality,
    AddRFactor,
    AddSamplingSize,
    AddIncludeNegativeValueCheck,
    AddIncludeNullValueCheck,
    AddRMMImpact,
    AddTOCImpact,
    AddSAPImpact,
    AddOSPImpact,
    AddDATImpact
} from '../../state-management/workbook/workbookSlice';
import RFactorSampleSize from '../../components/ui-components/RFactorSampleSize/RFactorSampleSize';
import '../../main.css';

export const SamplingCalculatorStepOne = () => {
    // Setup the page navigation.
    const navigate = useNavigate();
    // Setup outwards comms with redux using custom hook.
    const dispatch = useDispatch();
    // Create a localized object to centralize changes.
    let samplingConfigurationObj = {
        RMMImpact: useSelector((state) => state.sheets.RMMImpact),
        TOCImpact: useSelector((state) => state.sheets.TOCImpact),
        SAPImpact: useSelector((state) => state.sheets.SAPImpact),
        OSPImpact: useSelector((state) => state.sheets.OSPImpact),
        DATImpact: useSelector((state) => state.sheets.DATImpact),
        totalItems: useSelector((state) => state.sheets.totalItems),
        balance: useSelector((state) => state.sheets.balance),
        itemsAboveMaterialityThreshold: useSelector((state) => state.sheets.itemsAboveMaterialityThreshold),
        negativeItems: useSelector((state) => state.sheets.negativeItems),
        absoluteValueOfNegativeItems: useSelector((state) => state.sheets.absoluteValueOfNegativeItems),
        totalValueOfMaterialItems: useSelector((state) => state.sheets.valueOfItemsAboveMateriality),
        rFactor: useSelector((state) => state.sheets.rFactor),
        samplingSize: useSelector((state) => state.sheets.samplingSize),
        includeNegativeValueCheck: useSelector((state) => state.sheets.includeNegativeValueCheck),
        includeNullValueCheck: useSelector((state) => state.sheets.includeNullValueCheck),
        workbookMateriality: useSelector((state) => state.sheets.workbookMateriality),
        selectedSheet: useSelector((state) => state.sheets.selectedSheet),
        sampleDataColumn: useSelector((state) => state.sheets.sampleDataColumn)
    }
    const [samplingConfiguration, setSamplingConfiguration] = useState(samplingConfigurationObj)

    const handleNullValueCheckChanged = (e) => {
        setSamplingConfiguration((prevState) => ({ ...prevState, includeNullValueCheck: e.target.checked }));
        dispatch(AddIncludeNullValueCheck(e.target.checked));
    }

    const handleNegativeValueCheckChanged = (e) => {
        setSamplingConfiguration((prevState) => ({ ...prevState, includeNegativeValueCheck: e.target.checked }))
        dispatch(AddIncludeNegativeValueCheck(e.target.checked));
    }

    const UpdateRFactor = (e) => {
        setSamplingConfiguration({ ...samplingConfiguration, rFactor: e })
    }

    const UpdateSampleSize = (e) => {
        setSamplingConfiguration({ ...samplingConfiguration, samplingSize: e })
    }

    /**
     * Progress Calculation will move the application to the next stage after saving all the variables in the redux store.
     * These variables are handy for calculations.
     */
    const ProgressCalculation = () => {
        dispatch(AddAbsoluteValueOfNegativeItems(samplingConfiguration.absoluteValueOfNegativeItems));
        dispatch(AddBalance(samplingConfiguration.balance));
        dispatch(AddItemsAboveMaterialityThreshold(samplingConfiguration.itemsAboveMaterialityThreshold));
        dispatch(AddNegativeItems(samplingConfiguration.negativeItems));
        dispatch(AddTotalItems(samplingConfiguration.totalItems));
        dispatch(AddValueOfItemsAboveMateriality(samplingConfiguration.totalValueOfMaterialItems));
        dispatch(AddRFactor(samplingConfiguration.rFactor));
        dispatch(AddSamplingSize(samplingConfiguration.samplingSize));
        dispatch(AddIncludeNullValueCheck(samplingConfiguration.includeNullValueCheck));
        dispatch(AddIncludeNegativeValueCheck(samplingConfiguration.includeNegativeValueCheck));
        dispatch(AddRMMImpact(samplingConfiguration.RMMImpact));
        dispatch(AddTOCImpact(samplingConfiguration.TOCImpact));
        dispatch(AddSAPImpact(samplingConfiguration.SAPImpact));
        dispatch(AddOSPImpact(samplingConfiguration.OSPImpact));
        dispatch(AddDATImpact(samplingConfiguration.DATImpact));
        // move to page 2 of the calculation.
        navigate('/sampling-calculator-2');
    }

    return (
        <div className='w-1200-wrapper'>
            <div className='custom-container-constraint-left'>
                <Row>
                    <Col md={{ span: 12 }}>
                        <PageHeading label='Sampling Calculator 1' />
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 12 }}>
                        <p>
                            <span className='fst-italic'>Looks like you're trying to sample sheet: </span>
                            <span className='fw-bold'>{samplingConfiguration.selectedSheet}</span>
                            <span className='fst-italic'>, column: </span><span className='fw-bold'>{samplingConfiguration.sampleDataColumn}</span>
                        </p>
                    </Col>
                </Row>
                <Row className='mb-5'>
                    <Col md={{ span: 5 }}>
                        <Form.Group>
                            <Form.Label>Total Items</Form.Label>
                            <br />
                            <Form.Label>{samplingConfiguration.totalItems}</Form.Label>
                            {/* <Form.Control
                                disabled
                                type='number'
                                value={samplingConfiguration.totalItems}
                                onChange={(e) => setSamplingConfiguration({ ...samplingConfiguration, totalItems: e.target.value })} /> */}
                        </Form.Group>
                    </Col>

                    <Col md={{ span: 5, offset: 2 }}>
                        <Form.Group>
                            <Form.Label>Balance</Form.Label>
                            <br />
                            <Form.Label>{samplingConfiguration.balance}</Form.Label>
                            {/* <Form.Control
                                disabled
                                type='number'
                                value={samplingConfiguration.balance}
                                onChange={(e) => setSamplingConfiguration({ ...samplingConfiguration, balance: e.target.value })} /> */}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mb-5'>
                    <Col md={{ span: 5 }}>
                        <Form.Group>
                            <Form.Label>Items above performance materiality threshold</Form.Label>
                            <br />
                            <Form.Label>{samplingConfiguration.itemsAboveMaterialityThreshold}</Form.Label>
                            {/* <Form.Control
                                type='number'
                                disabled
                                value={samplingConfiguration.itemsAboveMaterialityThreshold}
                                onChange={(e) => setSamplingConfiguration({ ...samplingConfiguration, itemsAboveMaterialityThreshold: e.target.value })} /> */}
                        </Form.Group>
                    </Col>
                    <Col md={{ span: 5, offset: 2 }}>
                        <Form.Group>
                            <Form.Label>Negative Items</Form.Label>
                            <br />
                            <Form.Label>{samplingConfiguration.negativeItems}</Form.Label>
                            {/* <Form.Control
                                disabled
                                type='number'
                                value={samplingConfiguration.negativeItems}
                                onChange={(e) => setSamplingConfiguration({ ...samplingConfiguration, negativeItems: e.target.value })} /> */}
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='mb-5'>

                    <Col md={{ span: 5 }}>
                        <Form.Group>
                            <Form.Label>Absolute value of Negative Items</Form.Label>
                            <br />
                            <Form.Label>{samplingConfiguration.absoluteValueOfNegativeItems}</Form.Label>
                            {/* <Form.Control
                                disabled
                                type='number'
                                value={samplingConfiguration.absoluteValueOfNegativeItems}
                                onChange={(e) => setSamplingConfiguration({ ...samplingConfiguration, absoluteValueOfNegativeItems: e.target.value })} /> */}
                        </Form.Group>
                    </Col>

                    <Col md={{ span: 5, offset: 2 }}>
                        <Form.Group>
                            <Form.Label>Total value of items above performance materiality</Form.Label>
                            <br />
                            <Form.Label>{samplingConfiguration.totalValueOfMaterialItems}</Form.Label>
                            {/* <Form.Control
                                disabled
                                type='number'
                                value={samplingConfiguration.totalValueOfMaterialItems}
                                onChange={(e) => setSamplingConfiguration({ ...samplingConfiguration, totalValueOfMaterialItems: e.target.value })} /> */}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-5'>
                    {/* Include null values in the sample? */}
                    <Col md={{ span: 5 }}>
                        <ToggleSwitch
                            toggleId={'null-value-toggle'}
                            labelText={'Include null values in the sample?'}
                            isToggled={samplingConfiguration.includeNullValueCheck}
                            toggleChange={(e) => { handleNullValueCheckChanged(e) }} />
                    </Col>
                    {/* Include negative values in the sample? */}
                    <Col md={{ span: 5, offset: 2 }}>
                        {/* <ToggleSwitch
                            labelText={'Include negative values in the sample?'}
                            isToggled={samplingConfiguration.includeNegativeValueCheck}
                            toggleChange={(e) => { handleNegativeValueCheckChanged(e) }} /> */}
                    </Col>
                </Row>
                <Row className='mt-5'>
                    <Col md={{ span: 6 }}>
                        <a className='btn btn-outline-primary text-uppercase' href='/'>Start Over</a>
                    </Col>
                    <Col md={{ offset: 2 }} style={{ display: 'flex', justifyContent: 'end' }}>
                        <button className='btn btn-success text-uppercase' id='btn-sampling-next' onClick={ProgressCalculation}>Next Page</button>
                    </Col>
                </Row>
            </div>
            <div style={{ marginTop: '-34em' }}>
                <RFactorSampleSize
                    rMMImpactAssessment={samplingConfiguration.RMMImpact}
                    tOCImpactAssessment={samplingConfiguration.TOCImpact}
                    sAPImpactAssessment={samplingConfiguration.SAPImpact}
                    oSPImpactAssessment={samplingConfiguration.OSPImpact}
                    dATImpactAssessment={samplingConfiguration.DATImpact}
                    includeNegativeValueCheck={samplingConfiguration.includeNegativeValueCheck}
                    includeNullValueCheck={samplingConfiguration.includeNullValueCheck}
                    balance={samplingConfiguration.balance}
                    totalValueOfMaterialItems={samplingConfiguration.totalValueOfMaterialItems}
                    absoluteValueOfNegativeItems={samplingConfiguration.absoluteValueOfNegativeItems}
                    workbookMateriality={samplingConfiguration.workbookMateriality}
                    UpdateRFactor={UpdateRFactor}
                    UpdateSampleSize={UpdateSampleSize}
                />
            </div>
        </div>
    )
}
