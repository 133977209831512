import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    sheetNames: [],
    selectedSheet: null,
    workbookContainer: null,
    workbookFileName: null,
    sampleStart: null,
    sampleEnd: null,
    sampleDataColumn: null,
    workbookMateriality: null,
    optionalMateriality: null,
    absoluteValueOfNegativeItems: null,
    balance: null,
    itemsAboveMaterialityThreshold: null,
    negativeItems: null,
    totalItems: null,
    valueOfItemsAboveMateriality: null,
    includeNegativeValueCheck: false,
    includeNullValueCheck: false,
    RMMImpact: 0,
    TOCImpact: 0,
    SAPImpact: 0,
    OSPImpact: 0,
    DATImpact: 0,
    rFactor: 0,
    samplingSize: 0,
    comment: '',
    uuid: '',
    testName: null,
    sampleMethod: null,
    financialStatementArea: '',
    AddPeriodEnd: null,
    tokenAuth: null,
}

export const workbookSlice = createSlice({
    name: 'sheets',
    initialState,
    reducers: {
        ResetState: (state) => {
            state.sheetNames = [];
            state.selectedSheet = null;
            state.workbookContainer = null;
            state.workbookFileName = null;
            state.sampleStart = null;
            state.sampleEnd = null;
            state.sampleDataColumn = null;
            state.workbookMateriality = null;
            state.optionalMateriality = null;
            state.absoluteValueOfNegativeItems = null;
            state.balance = null;
            state.itemsAboveMaterialityThreshold = null;
            state.negativeItems = null;
            state.totalItems = null;
            state.valueOfItemsAboveMateriality = null;
            state.rFactor = 0;
            state.samplingSize = 0;
            state.RMMImpact = 0;
            state.TOCImpact = 0;
            state.SAPImpact = 0;
            state.OSPImpact = 0;
            state.DATImpact = 0;
            state.comment = '';
            state.uuid = 0;
            state.testName = null;
            state.sampleMethod = null;
            state.financialStatementArea = '';
            state.periodEnd = null;
            state.tokenAuth = null;
        },
        AddSheetList: (state, action) => {
            state.sheetNames.slice();
            state.sheetNames.push(...action.payload);
        },
        SetSelectedSheet: (state, action) => {
            state.selectedSheet = action.payload;
        },
        AddWorkbookContainer: (state, action) => {
            state.workbookContainer = action.payload;
        },
        AddWorkbookFilename: (state, action) => {
            state.workbookFileName = action.payload;
        },
        AddSampleStart: (state, action) => {
            state.sampleStart = action.payload;
        },
        AddSampleEnd: (state, action) => {
            state.sampleEnd = action.payload;
        },
        AddSampleDataColumn: (state, action) => {
            state.sampleDataColumn = action.payload;
        },
        AddWorkbookMateriality: (state, action) => {
            state.workbookMateriality = action.payload;
        },
        AddOptionalMateriality: (state, action) => {
            state.optionalMateriality = action.payload;
        },
        AddAbsoluteValueOfNegativeItems: (state, action) => {
            state.absoluteValueOfNegativeItems = action.payload;
        },
        AddBalance: (state, action) => {
            state.balance = action.payload;
        },
        AddItemsAboveMaterialityThreshold: (state, action) => {
            state.itemsAboveMaterialityThreshold = action.payload;
        },
        AddNegativeItems: (state, action) => {
            state.negativeItems = action.payload;
        },
        AddTotalItems: (state, action) => {
            state.totalItems = action.payload;
        },
        AddValueOfItemsAboveMateriality: (state, action) => {
            state.valueOfItemsAboveMateriality = action.payload;
        },
        AddRFactor: (state, action) => {
            state.rFactor = action.payload;
        },
        AddSamplingSize: (state, action) => {
            state.samplingSize = action.payload;
        },
        AddIncludeNegativeValueCheck: (state, action) => {
            state.includeNegativeValueCheck = action.payload;
        },
        AddIncludeNullValueCheck: (state, action) => {
            state.includeNullValueCheck = action.payload;
        },
        AddRMMImpact: (state, action) => {
            state.RMMImpact = action.payload;
        },
        AddTOCImpact: (state, action) => {
            state.TOCImpact = action.payload;
        },
        AddSAPImpact: (state, action) => {
            state.SAPImpact = action.payload;
        },
        AddOSPImpact: (state, action) => {
            state.OSPImpact = action.payload;
        },
        AddDATImpact: (state, action) => {
            state.DATImpact = action.payload;
        },
        AddComment: (state, action) => {
            state.comment = action.payload;
        },
        AddUuid: (state, action) => {
            state.uuid = action.payload;
        },
        AddTestName: (state, action) => {
            state.testName = action.payload;
        },
        AddSampleMethod: (state, action) => {
            state.sampleMethod = action.payload;
        },
        AddFinancialStatementArea: (state, action) => {
            state.financialStatementArea = action.payload;
        },
        AddPeriodEnd: (state, action) => {
            state.periodEnd = action.payload;
        },
        AddTokenAuth: (state, action) => {
            state.tokenAuth = action.payload;
        },
    }
})

export const {
    ResetState,
    AddSheetList,
    AddWorkbookContainer,
    AddWorkbookFilename,
    SetSelectedSheet,
    AddSampleStart,
    AddSampleEnd,
    AddSampleDataColumn,
    AddWorkbookMateriality,
    AddOptionalMateriality,
    AddAbsoluteValueOfNegativeItems,
    AddBalance,
    AddItemsAboveMaterialityThreshold,
    AddNegativeItems,
    AddTotalItems,
    AddValueOfItemsAboveMateriality,
    AddRFactor,
    AddSamplingSize,
    AddIncludeNegativeValueCheck,
    AddIncludeNullValueCheck,
    AddRMMImpact,
    AddTOCImpact,
    AddSAPImpact,
    AddOSPImpact,
    AddDATImpact,
    AddComment,
    AddUuid,
    AddTestName,
    AddSampleMethod,
    AddFinancialStatementArea,
    AddPeriodEnd,
    AddTokenAuth,
} = workbookSlice.actions;
export default workbookSlice.reducer;