import React, { useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap';
import { PageHeading } from '../../components/global-components/header/PageHeading';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// Redux toolkit imports to commit changes.
import { AddComment, AddRFactor, AddSampleMethod, AddSamplingSize } from '../../state-management/workbook/workbookSlice';
import axios from 'axios';
import CustomDropDown from '../../components/ui-components/CustomDropDown/CustomDropDown';
import RFactorSampleSize from '../../components/ui-components/RFactorSampleSize/RFactorSampleSize';
import CustomSpinner from '../../components/ui-components/CustomSpinner/CustomSpinner';
import '../../main.css';
import { Error } from '../../components/global-components/error/Error';

export const SamplingCalculatorStepThree = () => {
    // Setup the page navigation.
    const navigate = useNavigate();
    // Setup outwards comms with redux using custom hook.
    const dispatch = useDispatch();
    const [samplingMethod, setSamplingMethod] = useState('Stratified');
    const [isLoading, setisLoading] = useState(false);
    const [error, setError] = useState(false);

    // Create a localized object to centralize changes.
    let samplingConfigurationObj = {
        RMMImpact: useSelector((state) => state.sheets.RMMImpact),
        TOCImpact: useSelector((state) => state.sheets.TOCImpact),
        SAPImpact: useSelector((state) => state.sheets.SAPImpact),
        OSPImpact: useSelector((state) => state.sheets.OSPImpact),
        DATImpact: useSelector((state) => state.sheets.DATImpact),
        totalItems: useSelector((state) => state.sheets.totalItems),
        balance: useSelector((state) => state.sheets.balance),
        itemsAboveMaterialityThreshold: useSelector((state) => state.sheets.itemsAboveMaterialityThreshold),
        negativeItems: useSelector((state) => state.sheets.negativeItems),
        absoluteValueOfNegativeItems: useSelector((state) => state.sheets.absoluteValueOfNegativeItems),
        totalValueOfMaterialItems: useSelector((state) => state.sheets.valueOfItemsAboveMateriality),
        rFactor: useSelector((state) => state.sheets.rFactor),
        samplingSize: useSelector((state) => state.sheets.samplingSize),
        initialSampleSize: useSelector((state) => state.sheets.samplingSize),
        includeNegativeValueCheck: useSelector((state) => state.sheets.includeNegativeValueCheck),
        includeNullValueCheck: useSelector((state) => state.sheets.includeNullValueCheck),
        workbookMateriality: useSelector((state) => state.sheets.workbookMateriality),
        sampleSheet: useSelector((state) => state.sheets.selectedSheet),
        tableStart: useSelector((state) => state.sheets.sampleStart),
        tableEnd: useSelector((state) => state.sheets.sampleEnd),
        column: useSelector((state) => state.sheets.sampleDataColumn),
        performanceMateriality: useSelector((state) => state.sheets.workbookMateriality),
        optionalMateriality: useSelector((state) => state.sheets.optionalMateriality),
        comment: '',
        containerName: useSelector((state) => state.sheets.workbookContainer),
        fileName: useSelector((state) => state.sheets.workbookFileName),
        uuid: useSelector((state) => state.sheets.uuid),
        testName: useSelector((state) => state.sheets.testName),
        sampleMethod: useSelector((state) => state.sheets.sampleMethod),
        financialStatementArea: useSelector((state) => state.sheets.financialStatementArea),
        periodEnd: useSelector((state) => state.sheets.periodEnd),
        tokenAuth: useSelector((state) => state.sheets.tokenAuth),
    };

    const [samplingConfiguration, setSamplingConfiguration] = useState(samplingConfigurationObj)
    const ProcessSample = async () => {
        setisLoading(true);
        dispatch(AddComment(samplingConfiguration.comment));
        dispatch(AddSampleMethod(samplingConfiguration.sampleMethod));
        await postInformation();
    }

    const postInformation = async () => {
        let bearer = `Bearer ${samplingConfigurationObj.tokenAuth}`;

        setisLoading(true);

        let config = {
            headers: {
                'Authorization': `${bearer}`,
                'Content-Type': 'application/json',
            }
        }

        var combinedSeedValue = samplingConfiguration.performanceMateriality + samplingConfiguration.rFactor;

        if (samplingConfiguration.includeNullValueCheck)
            combinedSeedValue++;

        if (samplingConfiguration.includeNegativeValueCheck)
            combinedSeedValue++;

        combinedSeedValue = Math.round(combinedSeedValue);

        if (samplingConfiguration.sampleMethod === 'MUS') {
            samplingConfiguration.includeNegativeValueCheck = false;
            //samplingConfiguration.initialSampleSize = Math.ceil((samplingConfiguration.balance * samplingConfiguration.rFactor) / samplingConfiguration.workbookMateriality);
        }

        let body = {
            'sheetName': samplingConfiguration.sampleSheet,
            'tableStart': samplingConfiguration.tableStart,
            'tableEnd': samplingConfiguration.tableEnd,
            'columnLetter': samplingConfiguration.column ? samplingConfiguration.column.toUpperCase() : '',
            'accountBalance': samplingConfiguration.balance,
            'numberOfItems': samplingConfiguration.totalItems,
            'numberOfNegativeItems': samplingConfiguration.negativeItems,
            'itemsAboveMaterialityThreshold': samplingConfiguration.itemsAboveMaterialityThreshold,
            'absoluteValueOfNegativeItems': samplingConfiguration.absoluteValueOfNegativeItems,
            'absoluteValueOfMaterialityItems': samplingConfiguration.totalValueOfMaterialItems,
            'rmmLevel': samplingConfiguration.RMMImpact,
            'tocAssurance': samplingConfiguration.TOCImpact,
            'sapAssurance': samplingConfiguration.SAPImpact,
            'ospAssurance': samplingConfiguration.OSPImpact,
            'datAssurance': samplingConfiguration.DATImpact,
            'rFactor': samplingConfiguration.rFactor,
            'initialSampleSize': samplingConfiguration.initialSampleSize,
            'initialSampleSizeRounded': samplingConfiguration.initialSampleSize,
            'includeNegatives': samplingConfiguration.includeNegativeValueCheck,
            'includeNulls': samplingConfiguration.includeNullValueCheck,
            'seedValue': combinedSeedValue,
            'comments': samplingConfiguration.comment,
            'performanceMateriality': samplingConfiguration.performanceMateriality,
            'optionalMateriality': samplingConfiguration.optionalMateriality,
            'containerName': samplingConfiguration.containerName,
            'fileName': samplingConfiguration.fileName,
            'staticContainerName': 'static-files',
            'staticFileName': 'OSP Sampling Calculator.xlsx',
            'samplingMethod': samplingConfiguration.sampleMethod || samplingMethod,
            'uuid': samplingConfiguration.uuid,
            'financialStatementArea': samplingConfiguration.financialStatementArea,
            'periodEnd': samplingConfiguration.periodEnd,
            'testname': samplingConfiguration.testName,
        }

        const calculateSampleUrl = process.env.REACT_APP_POPULATE_CALCULATOR;
        const processFilesUrl = process.env.REACT_APP_PROCESS_SAMPLE;
        try {
            await axios.post(calculateSampleUrl, body, config)
                .then((response) => {
                    // calculateSampleUrlResponse = response.data
                    
                });
        } catch (error) {
            console.error(`PopulateSampleCalculator: ${error}`);
            setError(true);
        }
        body.fileName = samplingConfiguration.fileName;
        try {
            await axios.post(processFilesUrl, body, config)
                .then((response) => {
                    navigate('/download-output');
                });
        } catch (error) {
            console.error(`ProcessSample ${error}`);
            setError(true);
        }
    }

    // sets the sampling method for the calculation.
    const handleSamplingMethodChange = (value) => {
        if (value === 'Monetary Unit') {
            value = 'MUS';
        }
        setSamplingMethod(value);
        setSamplingConfiguration({ ...samplingConfiguration, sampleMethod: value });
    }

    const UpdateRFactor = (e) => {
        setSamplingConfiguration({ ...samplingConfiguration, rFactor: e })
        dispatch(AddRFactor(e));
    }

    const UpdateSampleSize = (e) => {
        setSamplingConfiguration({ ...samplingConfiguration, samplingSize: e })
        dispatch(AddSamplingSize(e));
    }

    return (
        <div className='w-1200-wrapper'>
            {error && <Error />}
            <div className='custom-container-constraint-left'>
                <Row>
                    <Col md={{ span: 12 }}>
                        <PageHeading label='Sampling Calculator 3' />
                        <CustomSpinner isLoading={isLoading} />
                    </Col>
                    <Col>
                        <RFactorSampleSize
                            rMMImpactAssessment={samplingConfiguration.RMMImpact}
                            tOCImpactAssessment={samplingConfiguration.TOCImpact}
                            sAPImpactAssessment={samplingConfiguration.SAPImpact}
                            oSPImpactAssessment={samplingConfiguration.OSPImpact}
                            dATImpactAssessment={samplingConfiguration.DATImpact}
                            includeNegativeValueCheck={samplingConfiguration.includeNegativeValueCheck}
                            includeNullValueCheck={samplingConfiguration.includeNullValueCheck}
                            balance={samplingConfiguration.balance}
                            totalValueOfMaterialItems={samplingConfiguration.totalValueOfMaterialItems}
                            absoluteValueOfNegativeItems={samplingConfiguration.absoluteValueOfNegativeItems}
                            workbookMateriality={samplingConfiguration.workbookMateriality}
                            UpdateRFactor={UpdateRFactor}
                            UpdateSampleSize={UpdateSampleSize}
                            rFactorOverride={samplingConfiguration.rFactor}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 11 }}>
                        <p>Please comment on other procedures performed (optional)</p>
                    </Col>
                </Row>
                <Row className='mb-2'>
                    <Col md={{ span: 12 }}>
                        <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                            <Form.Control as='textarea' rows={3} onChange={(e) => setSamplingConfiguration({ ...samplingConfiguration, comment: e.target.value })} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 12 }}>
                        <CustomDropDown
                            options={[
                                'Stratified',
                                'Interval',
                                'Random',
                                'Monetary Unit',
                            ]}
                            dropDownId='sampling-method-select'
                            title={samplingMethod}
                            label='Please select the sample selection method from below.'
                            handleChange={handleSamplingMethodChange}
                            additionalStyling='zindex-1'
                        />
                        {
                            (samplingMethod === 'Interval' || samplingMethod === 'Random') &&
                            <p class='text-secondary'>If you have selected Random or Interval, then the sample size will be multipled by 1.25x in the sample calculator output</p>
                        }
                    </Col>
                </Row>
                <Row style={{ marginTop: '12rem' }}>
                    <Col md={{ span: 6 }}>
                        <a className='btn btn-outline-primary text-uppercase' href='/'>Start Over</a>
                    </Col>
                    <Col md={{ offset: 2 }} style={{ display: 'flex', justifyContent: 'end' }}>
                        <button className='btn btn-success text-uppercase' id='btn-sampling-next' onClick={ProcessSample}>Process Sample</button>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
