import { useState } from 'react';
import { Col, Form, SplitButton, Dropdown } from 'react-bootstrap';
import { config } from '../../global-components/config';

const ImpactDropDown = ({ impactName, handleAssessmentChange }) => {
    let impact = config.find(c => c.name === impactName);
    const [selectedAssessment, setSelectedAssessment] = useState(impact.assessments[0]);
    const ChangeAssessmentHandler = (assessment) => {
        setSelectedAssessment(assessment);
        handleAssessmentChange(assessment)
    }
    return (
        <>
            <Col md={{ span: 4 }}>
                <Form.Group>
                    <Form.Label style={{ lineHeight: '2.5rem' }}>{impact.title}</Form.Label>
                </Form.Group>
            </Col>

            <Col md={{ span: 4, offset: 1 }} className='text-center'>
                {/* SplitButton drop down to match the original design. */}
                <SplitButton
                    align={{ lg: 'start' }}
                    title={selectedAssessment.riskConsideration}
                    className='split-dropdown'
                    data-name={selectedAssessment.riskConsideration}
                >
                    {
                        impact.assessments.map((assessment, index) => {
                            return <Dropdown.Item
                                eventKey={index}
                                key={index}
                                onClick={() => { ChangeAssessmentHandler(assessment) }}>
                                {assessment.riskConsideration}
                            </Dropdown.Item>
                        })
                    }
                </SplitButton>
            </Col>

            <Col md={{ span: 1, offset: 2 }}>
                <Form.Group>
                    <Form.Label style={{ lineHeight: '2.5rem' }}>{selectedAssessment.assessment}</Form.Label>
                </Form.Group>
            </Col>
        </>
    );
}

export default ImpactDropDown;
