import React, { useState, useRef } from 'react'

export const FileUploader = ({
    handleFile,
    labelForNoFiles,
    labelForSelectText,
    maxUploadCount,
    labelForMaxNumberOfFiles }) => {
    // Create a reference to the hidden file input element
    const hiddenFileInput = useRef(null);
    const [files, setFiles] = useState([])

    /**
     * Programatically click the hidden file input element
     * when the Button component is clicked
     */
    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    /**
     * Check if maximum number of number allowance reached.
     */
    const isMaxCountReached = () => {
        return maxUploadCount === files.length
    }

    /**
     * Call a function (passed as a prop from the parent component)
     * to handle the user-selected file 
     */
    const handleChange = (e) => {
        const fileUploaded = e.target.files[0]

        if (fileUploaded === undefined) return

        let fileExtension = fileUploaded.name.substring(fileUploaded.name.lastIndexOf('.'))
        if (fileExtension !== '.xlsx' && fileExtension !== '.xls') return

        if (isMaxCountReached()) return

        if (files.filter(f => f.name === fileUploaded.name).length > 0) return

        setFiles((prevState) => [...prevState, fileUploaded])
        handleFile(fileUploaded);

    };

    const handleOnRemove = (e) => {
        e.stopPropagation()
        let name = e.target.getAttribute('data-id')
        setFiles(files.filter(f => f.name !== name))
    }

    return (
        <>
            <ul className='file-upload-container' onClick={handleClick}>
                {
                    files.length === 0 &&
                    <div>
                        <p className='file-upload-label'>{labelForNoFiles}</p>
                        <button className='btn btn-outline-success text-uppercase'>{labelForSelectText}</button>
                    </div>
                }
                {files.map((f, i) => {
                    return (
                        <li key={i}>
                            <div className='file-upload-content'>
                                <p className='file-uploaded-label'>
                                    <img className='file-uploaded-icon' src={`${process.env.PUBLIC_URL}/images/xls-icon.svg`} alt='Excel logo' /> {f?.name}
                                </p>
                                <span className='file-uploaded-remove-icon' data-id={f?.name} onClick={handleOnRemove}></span>
                            </div>
                        </li>
                    )
                })}
                {isMaxCountReached() && <p><span className='file-limit-info-text'>{labelForMaxNumberOfFiles}</span></p>}
            </ul>

            <input
                type='file'
                ref={hiddenFileInput}
                onChange={handleChange}
                accept='.xlsx'
                style={{ display: 'none' }} /> {/* Make the file input element invisible */}
        </>
    )
}