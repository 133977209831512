import React from 'react'
import { Breadcrumb, Container } from 'react-bootstrap'
import { Routes, Route, useLocation } from 'react-router-dom';
import { AttachWorkbook } from '../../../../pages/AttachWorkbook';
import './BreadCrumbNavigation.css';
import { SheetSelection } from './../../../../pages/SheetSelection';
import { DefineSheetInformation } from './../../../../pages/DefineSheetInformation';
import { SamplingCalculatorStepOne } from '../../../../pages/SamplingCalculatorSteps/SamplingCalculatorStepOne'
import { SamplingCalculatorStepTwo } from '../../../../pages/SamplingCalculatorSteps/SamplingCalculatorStepTwo'
import { SamplingCalculatorStepThree } from '../../../../pages/SamplingCalculatorSteps/SamplingCalculatorStepThree'
import { DownloadOutput } from '../../../../pages/DownloadOutput'
import { NotFound } from '../../../../pages/NotFound';
import { BdoNav } from '../../../../components/global-components/header/BdoNav';

export const BreadCrumbNavigation = () => {
  const navItems = [
    { href: '/', name: 'Attach Workbook', component: 'AttachWorkbook', active: false },
    { href: '/sheet-selection', name: 'Sheet Selection', component: 'SheetSelection', active: false },
    // { href: '/select-samplingmethod', name: 'Select Sampling Method', component: 'SheetSelection', active: false },
    { href: '/define-sheet-information', name: 'Define sheet information', component: 'DefineSheetInformation', active: false },
    { href: '/sampling-calculator-1', name: 'Sampling calculator 1', component: 'SamplingCalculatorStepOne', active: false },
    { href: '/sampling-calculator-2', name: '2', component: 'SamplingCalculatorStepTwo', active: false },
    { href: '/sampling-calculator-3', name: '3', component: 'SamplingCalculatorStepThree', active: false },
    { href: '/download-output', name: 'Download Output', component: 'DownloadOutput', active: false },
  ];

  const { pathname } = useLocation();

  return (
    <>
      <BdoNav />

      <div className='breadcrumb-container py-1'>
        <section className='breadcrumb-nav'>
          <Breadcrumb>
            {
              navItems.map((item, count) => {
                return (
                  <Breadcrumb.Item
                    key={count}
                    active={item.href === pathname}>
                    {item.name}
                  </Breadcrumb.Item>
                )
              })
            }
            <img className='labs-watermark-img' src={`${process.env.PUBLIC_URL}/images/Labs identifier - WHITE - AUDIT.png`} alt='Labs logo' />
          </Breadcrumb>
        </section>
      </div>
      <Container fluid>
        <Routes>
          <Route path='/' element={<AttachWorkbook />} />
          <Route path='/attachworkbook' element={<AttachWorkbook />} />
          <Route path='/sheet-selection' element={<SheetSelection />} />
          <Route path='/define-sheet-information' element={<DefineSheetInformation />} />
          <Route path='/sampling-calculator-1' element={<SamplingCalculatorStepOne />} />
          <Route path='/sampling-calculator-2' element={<SamplingCalculatorStepTwo />} />
          <Route path='/sampling-calculator-3' element={<SamplingCalculatorStepThree />} />
          <Route path='/download-output' element={<DownloadOutput />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Container>
    </>
  )
}
