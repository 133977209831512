import React from 'react'
import './ToggleSwitch.css';

export const ToggleSwitch = ({ labelText, toggleChange, isToggled, toggleId }) => {
    return (
        <div className='toggle-switch'>
            <label className='toggle-switch-label' htmlFor={toggleId}>
                {labelText}
            </label>
            <label className='toggle-switch'>
                <input type='checkbox' id={toggleId} onChange={toggleChange} />
                <span className='switch' />
            </label>
            <p className='switch-status-text'>{isToggled ? 'Yes' : 'No'}</p>
        </div>
    )
}
