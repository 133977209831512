import { Form, Col, SplitButton, Dropdown } from 'react-bootstrap';
import { useState } from 'react';

// Custom split button drop down to show dropdown. @params: options, title to show and label.
const CustomDropDown = ({ options, title, label, handleChange, additionalStyling, dropDownId }) => {
    const [dropdownTitle, setDropdownTitle] = useState(options[0])
    const handleDropdownClick = (option) => {
        setDropdownTitle(option);
        handleChange(option);
    }
    return (
        <>
            <Col md={{ span: 6 }}>
                <Form.Group>
                    <Form.Label htmlFor={dropDownId} style={{ lineHeight: '2.5rem' }}>{label}</Form.Label>
                </Form.Group>
                <SplitButton
                    if={dropDownId}
                    align={{ lg: 'start' }}
                    title={dropdownTitle}
                    className='split-dropdown'
                    data-name={dropdownTitle}
                >
                    {
                        options.map((option, index) => {
                            return <Dropdown.Item
                                eventKey={index}
                                key={index}
                                onClick={() => { handleDropdownClick(option) }}
                            >
                                {option}
                            </Dropdown.Item>
                        })
                    }
                </SplitButton>
            </Col>
        </>
    );
}

export default CustomDropDown;