import { useIsAuthenticated } from '@azure/msal-react';
import { SignInButton } from '../../auth-components/SignInButton';
import { SignOutButton } from '../../auth-components/SignOutButton';
import './BdoNav.css';
import { Row } from 'react-bootstrap';

export const BdoNav = () => {
    // get location from hook.
    const isAuthenticated = useIsAuthenticated();
    return (
        <Row>
            <header className='masthead'>
                <section className='branding'><a className='homepageLink' href='/' title='Inventory'>
                    <svg xmlns='http://www.w3.org/2000/svg' className='bdoLogo' viewBox='0 0 283.47 108.86'>
                        <g id='Artwork'>
                            <path
                                d='M53.77 45.12h9.87c16.23 0 24.5 3.55 24.5 13 0 8.42-6.64 12-17 12H55.3L37.24 82.78H71c20.2 0 33.81-7.28 33.81-24.11 0-14.68-12-20.45-22.79-20.45 7.43 0 16.2-6.65 16.2-17.93S87.88.91 76.12.91h-41.9l3 6.7v69.28l16.55-11.57Zm0-31.56H71.1c4.76 0 10.78 1.13 10.78 9.21s-8.61 11.37-15 11.37H53.77ZM132 13.56h11.26c4.65 0 28.34 1.17 28.34 28.29 0 30.93-28.34 28.28-28.34 28.28h-9.72l-18.08 12.65h32.44c20.2 0 40.92-12.43 40.92-40.94 0-25.27-18.09-40.93-40.92-40.93h-35.46l3 6.7v69.28L132 65.32ZM196.46 41.84c0 32.67 26.4 41.85 43.5 41.85s43.51-9.18 43.51-41.85S257.07 0 240 0s-43.54 9.18-43.54 41.84Zm17.22 0c0-23.15 15.95-29.65 26.28-29.65s26.29 6.5 26.29 29.65S250.3 71.5 240 71.5s-26.32-6.5-26.32-29.66Z'
                                className='bdoBlue' />
                            <path d='M0 .91v102.06l16.15-11.31V.91H0zM19.17 95.44 0 108.86h282.57V95.44H19.17z'
                                className='bdoRed' />
                        </g>
                    </svg>
                </a>
                    <h1 className='text-uppercase'>Sample Generator</h1>
                    {isAuthenticated ? <SignOutButton /> : <SignInButton />}
                </section>
            </header>
        </Row>
    )
}
