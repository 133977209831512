import React from 'react';
import { useMsal } from '@azure/msal-react';
import Button from 'react-bootstrap/Button';
import { loginRequest } from '../../authConfig';

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect(loginRequest)
            .catch(e => {
                console.error(e);
            });
    }

    return (
        <Button variant='secondary' className='ml-auto' onClick={() => handleLogin()}>Sign in</Button>
    );
}