import React, { useState } from 'react'
import { Form, Row, Col, SplitButton, Dropdown } from 'react-bootstrap';
import { PageHeading } from './../../components/global-components/header/PageHeading';
import ImpactDropDown from '../../components/ui-components/custom-dropdown/ImpactDropDown';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { config } from '../../components/global-components/config';

// Redux toolkit imports to commit changes.
import {
    AddAbsoluteValueOfNegativeItems,
    AddBalance,
    AddItemsAboveMaterialityThreshold,
    AddNegativeItems,
    AddTotalItems,
    AddValueOfItemsAboveMateriality,
    AddRFactor,
    AddSamplingSize,
    AddIncludeNegativeValueCheck,
    AddIncludeNullValueCheck,
    AddRMMImpact,
    AddTOCImpact,
    AddSAPImpact,
    AddOSPImpact,
    AddDATImpact,
} from '../../state-management/workbook/workbookSlice';
import RFactorSampleSize from '../../components/ui-components/RFactorSampleSize/RFactorSampleSize';
import '../../main.css';

export const SamplingCalculatorStepTwo = () => {
    // Setup the page navigation.
    const navigate = useNavigate();
    // Setup outwards comms with redux using custom hook.
    const dispatch = useDispatch();
    // Create a localized object to centralize changes.
    let samplingConfigurationObj = {
        RMMImpact: useSelector((state) => state.sheets.RMMImpact),
        TOCImpact: useSelector((state) => state.sheets.TOCImpact),
        SAPImpact: useSelector((state) => state.sheets.SAPImpact),
        OSPImpact: useSelector((state) => state.sheets.OSPImpact),
        DATImpact: useSelector((state) => state.sheets.DATImpact),
        totalItems: useSelector((state) => state.sheets.totalItems),
        balance: useSelector((state) => state.sheets.balance),
        itemsAboveMaterialityThreshold: useSelector((state) => state.sheets.itemsAboveMaterialityThreshold),
        negativeItems: useSelector((state) => state.sheets.negativeItems),
        absoluteValueOfNegativeItems: useSelector((state) => state.sheets.absoluteValueOfNegativeItems),
        totalValueOfMaterialItems: useSelector((state) => state.sheets.valueOfItemsAboveMateriality),
        rFactor: useSelector((state) => state.sheets.rFactor),
        samplingSize: useSelector((state) => state.sheets.samplingSize),
        includeNegativeValueCheck: useSelector((state) => state.sheets.includeNegativeValueCheck),
        includeNullValueCheck: useSelector((state) => state.sheets.includeNullValueCheck),
        workbookMateriality: useSelector((state) => state.sheets.workbookMateriality),
        selectedSheet: useSelector((state) => state.sheets.selectedSheet),
        sampleDataColumn: useSelector((state) => state.sheets.sampleDataColumn)
    }

    const TOC_Impact = config.find(c => c.name === 'TOC_Impact');
    const RMM_Impact = config.find(c => c.name === 'RMM_Impact');
    const SAP_Impact = config.find(c => c.name === 'SAP_Impact');
    const OSP_Impact = config.find(c => c.name === 'OSP_Impact');
    const DAT_Impact = config.find(c => c.name === 'DAT_Impact');
    const [samplingConfiguration, setSamplingConfiguration] = useState(samplingConfigurationObj)

    
    const isTOCAssuranceTooHigh = () => {
        let Assurance = samplingConfiguration.TOCImpact + samplingConfiguration.SAPImpact + samplingConfiguration.OSPImpact + samplingConfiguration.DATImpact
        if ((samplingConfiguration.RMMImpact < 0.5) || (samplingConfiguration.RMMImpact > samplingConfiguration.TOCImpact) || (Assurance > samplingConfiguration.TOCImpact)) {
            return false
        }
        return true
    }

    const isSampleSizeZero = () => {
        if (samplingConfiguration.samplingSize > 0.00) {
            return false
        }
        return true
    }

    const ProgressCalculation = () => {
        // console.log(samplingConfiguration);
        dispatch(AddAbsoluteValueOfNegativeItems(samplingConfiguration.absoluteValueOfNegativeItems));
        dispatch(AddBalance(samplingConfiguration.balance));
        dispatch(AddItemsAboveMaterialityThreshold(samplingConfiguration.itemsAboveMaterialityThreshold));
        dispatch(AddNegativeItems(samplingConfiguration.negativeItems));
        dispatch(AddTotalItems(samplingConfiguration.totalItems));
        dispatch(AddValueOfItemsAboveMateriality(samplingConfiguration.totalValueOfMaterialItems));
        dispatch(AddRFactor(samplingConfiguration.rFactor));
        dispatch(AddSamplingSize(samplingConfiguration.samplingSize));
        dispatch(AddIncludeNullValueCheck(samplingConfiguration.includeNullValueCheck));
        dispatch(AddIncludeNegativeValueCheck(samplingConfiguration.includeNegativeValueCheck));
        dispatch(AddRMMImpact(samplingConfiguration.RMMImpact));
        dispatch(AddTOCImpact(samplingConfiguration.TOCImpact));
        dispatch(AddSAPImpact(samplingConfiguration.SAPImpact));
        dispatch(AddOSPImpact(samplingConfiguration.OSPImpact));
        dispatch(AddDATImpact(samplingConfiguration.DATImpact));
        navigate('/sampling-calculator-3');
    }

    const handleRMMImpactAssessmentChange = (e) => {
        setSamplingConfiguration((prevState) => ({ ...prevState, RMMImpact: e.assessment }))
        dispatch(AddRMMImpact(e.assessment));
    }
    const handleTOCImpactAssessmentChange = (e) => {
        setSamplingConfiguration((prevState) => ({ ...prevState, TOCImpact: e.assessment }))
        dispatch(AddTOCImpact(e.assessment));
    }
    const handleSAPImpactAssessmentChange = (e) => {
        setSamplingConfiguration((prevState) => ({ ...prevState, SAPImpact: e.assessment }))
        dispatch(AddSAPImpact(e.assessment));
    }
    const handleOSPImpactAssessmentChange = (e) => {
        setSamplingConfiguration((prevState) => ({ ...prevState, OSPImpact: e.assessment }))
        dispatch(AddOSPImpact(e.assessment));
    }
    const handleDATImpactAssessmentChange = (e) => {
        setSamplingConfiguration((prevState) => ({ ...prevState, DATImpact: e.assessment }))
        dispatch(AddDATImpact(e.assessment));
    }

    const UpdateRFactor = (e) => {
        setSamplingConfiguration((prevState) => ({ ...prevState, rFactor: e }));
    }

    const UpdateSampleSize = (e) => {
        setSamplingConfiguration((prevState) => ({ ...prevState, samplingSize: e }));
    }

    return (
        <div>
            <div>
                <Row style={{'place-content': 'center'}}>
                    <Col md={{ span: 3}} style={{width: '35%'}}>
                        <Row>
                            <Col md={{ span: 12 }}>
                                <PageHeading label='Sampling Calculator 2' />
                            </Col>
                            <RFactorSampleSize
                                rMMImpactAssessment={samplingConfiguration.RMMImpact}
                                tOCImpactAssessment={samplingConfiguration.TOCImpact}
                                sAPImpactAssessment={samplingConfiguration.SAPImpact}
                                oSPImpactAssessment={samplingConfiguration.OSPImpact}
                                dATImpactAssessment={samplingConfiguration.DATImpact}
                                includeNegativeValueCheck={samplingConfiguration.includeNegativeValueCheck}
                                includeNullValueCheck={samplingConfiguration.includeNullValueCheck}
                                balance={samplingConfiguration.balance}
                                totalValueOfMaterialItems={samplingConfiguration.totalValueOfMaterialItems}
                                absoluteValueOfNegativeItems={samplingConfiguration.absoluteValueOfNegativeItems}
                                workbookMateriality={samplingConfiguration.workbookMateriality}
                                UpdateRFactor={UpdateRFactor}
                                UpdateSampleSize={UpdateSampleSize}
                            />
                        </Row>
                        <Row>
                            <Col md={{ span: 12 }}>
                                <p><span className='fst-italic'>Looks like you're trying to sample sheet: </span><span className='fw-bold'>{samplingConfiguration.selectedSheet}</span><span className='fst-italic'>, column: </span><span className='fw-bold'>{samplingConfiguration.sampleDataColumn}</span></p>
                            </Col>
                        </Row>
                        <Row className='mb-2'>
                            <Col md={{ span: 4 }}>
                                <h5>Risk Consideration</h5>
                            </Col>

                            <Col md={{ span: 4, offset: 2 }}>
                                <h5>Assessment</h5>
                            </Col>

                            <Col md={{ span: 1, offset: 1 }}>
                                <h5>Impact</h5>
                            </Col>
                        </Row>
                        <Row className='mb-5'>
                            <ImpactDropDown impactName={RMM_Impact.name} handleAssessmentChange={(e) => { handleRMMImpactAssessmentChange(e) }} />
                        </Row>
                        <Row className='mb-5'>
                            <ImpactDropDown impactName={TOC_Impact.name} handleAssessmentChange={(e) => { handleTOCImpactAssessmentChange(e) }} />
                        </Row>
                        <Row className='mb-5'>
                            <ImpactDropDown impactName={SAP_Impact.name} handleAssessmentChange={(e) => { handleSAPImpactAssessmentChange(e) }} />
                        </Row>
                        <Row className='mb-5'>
                            <ImpactDropDown impactName={OSP_Impact.name} handleAssessmentChange={(e) => { handleOSPImpactAssessmentChange(e) }} />
                            
                                <Form.Text muted>
                                 Assurance from other OSPs is not permitted when sampling revenue or income balances
                                </Form.Text>          
                        </Row>
                        <Row className='mb-3'>
                            <ImpactDropDown impactName={DAT_Impact.name} handleAssessmentChange={(e) => { handleDATImpactAssessmentChange(e) }} />
                        </Row>
                        <Row>
                            <Col md={{ span: 3 }} className='text-center'>
                                <a className='btn btn-outline-primary text-uppercase' href='/'>Start Over</a>
                            </Col>

                            <Col md={{ offset: 2 }} style={{ display: 'flex', justifyContent: 'end' }}>
                                <button
                                    // disabled={isTOCAssuranceTooHigh()}
                                    className='btn btn-success text-uppercase' id='btn-sampling-next'
                                    onClick={ProgressCalculation}>Next Page</button>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={{ span: 2}} className='calculator-page-two-warnings'>
                        {   
                            (isSampleSizeZero() || isTOCAssuranceTooHigh()) &&
                            <Row>
                            <div>
                                <h3>Warning</h3>
                            </div>
                            </Row>
                        }
                        {   
                            isSampleSizeZero() &&
                            <Row>
                            <div>
                                <p>You must consult with TSG – Audit Advisory Helpline where you have planned a sampling OSP but the final OSP sampling R-factor here is 0.00.</p>
                            </div>
                            </Row>
                        }
                        {
                            isTOCAssuranceTooHigh() &&
                            <Row>
                            <div>
                                <p>
                                TOCs are unable to provide 100% of the assurance required over an FSA/Assertion, even if successful. If successful TOCs are performed for an FSA/Assertion where the IRMM level is Low or Moderate, TOCs must be supplemented by OSPs, SAPs or DATs
                                </p>
                                <p>
                                If assurance from SAPs or DATs are not taken, the OSP R-Factor will default to 0.5 and the OSP sampling formula will calculate a sample size.
                                </p>
                            </div>
                            </Row>
                        }
                    </Col>
                </Row>
            </div>
        </div >
    )
}
