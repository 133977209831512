import React from 'react';
import { useMsal } from '@azure/msal-react';
import Button from 'react-bootstrap/Button';

function handleLogout(instance) {
    instance.logoutRedirect().catch(e => {
    });
}

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const SignOutButton = () => {
    const { instance } = useMsal();

    return (
        <Button variant='outline-secondary' className='ml-auto' onClick={() => handleLogout(instance)}>Sign out</Button>
    );
}